import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Form } from 'react-bootstrap';

const PartnerTable = ({
  partners,
  setPartners,
  appsIndexes,
  onEditPartner,
  onHideAll,
  onDeletePartner,
  onCheckboxChange,
  updateSortData
}) => {
  const [selectedIndex, setSelectedIndex] = useState('01');
  const [sortedPartners, setSortedPartners] = useState(partners);

  useEffect(() => {
    const updatedSortedPartners = partners
      .slice()
      .sort((a, b) => {
        const aValue = a[`sort_${selectedIndex}`] || 0;
        const bValue = b[`sort_${selectedIndex}`] || 0;
        return aValue - bValue;
      });
    setSortedPartners(updatedSortedPartners);
  }, [partners, selectedIndex]);

  const handleChange = (e) => {
    setSelectedIndex(e.target.value);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(sortedPartners);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newPartners = items.map((partner, index) => {
      return {...partner, [`sort_${selectedIndex}`]: index};
    });

    setPartners(newPartners);
    updateSortData(
      newPartners.reduce((acc, partner) => {
        acc[partner.id] = partner[`sort_${selectedIndex}`];
        return acc;
      }, {}),
      selectedIndex
    );
  };

  return (<div className="container mt-5">
    <Form.Control as="select" value={selectedIndex} onChange={handleChange}>
      {Object.keys(appsIndexes).map((key) => (
        <option key={key} value={key}>
          {appsIndexes[key]}
        </option>
      ))}
    </Form.Control>
    <div className="table-responsive">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="partners">
          {(provided) => (<table
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="table table-bordered border table-striped table-hover mt-4"
          >
            <thead>
            <tr>
              <th className="text-center">Name</th>
              <th className="text-center">Desc</th>
              <th className="text-center">Amount</th>
              <th className="text-center age-column">Age</th>
              <th className="text-center">Rate</th>
              <th className="text-center">Active</th>
              <th className="text-center">Selected</th>
              <th className="text-center actions-column">Actions</th>
            </tr>
            </thead>
            <tbody>
            {sortedPartners.map((partner, index) => (<Draggable key={partner.id} draggableId={partner.id} index={index}>
              {(provided) => (<tr
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                key={partner.id}
              >
                <td>{partner.name}</td>
                <td>{partner.description}</td>
                <td>{partner.amount}</td>
                <td>{partner.age}</td>
                <td>{partner.rate}</td>
                <td className="text-center custom-checkbox">
                  <input type="checkbox" checked={partner[`isActive_${selectedIndex}`]}
                         onChange={() => onCheckboxChange(partner.id, `isActive_${selectedIndex}`, !partner[`isActive_${selectedIndex}`])}/>
                </td>
                <td className="text-center custom-checkbox">
                  <input type="checkbox" checked={partner[`isSelected_${selectedIndex}`]}
                         onChange={() => onCheckboxChange(partner.id, `isSelected_${selectedIndex}`, !partner[`isSelected_${selectedIndex}`])}/>
                </td>
                <td className="text-center">
                  <FontAwesomeIcon className="text-secondary mr-2" icon={faEdit}
                                   onClick={() => onEditPartner(partner)}/>
                  <FontAwesomeIcon className="text-secondary mr-2" icon={faEyeSlash}
                                   onClick={() => onHideAll(partner.id, partner.name)}/>
                  <FontAwesomeIcon className="text-secondary" icon={faTrash}
                                   onClick={() => onDeletePartner(partner.id, partner.name)}/>
                </td>
              </tr>)}
            </Draggable>))}
            {provided.placeholder}
            </tbody>
          </table>)}
        </Droppable>
      </DragDropContext>
    </div>
  </div>);
};

export default PartnerTable;
