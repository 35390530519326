import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Partners from './pages/Partners';
import Texts from './pages/Texts';
import Calendar from './pages/Calendar';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import AuthWrapper from './components/AuthWrapper';

const App = () => {
  const [country, setCountry] = React.useState('ua');

  useEffect(() => {
    const countryParam = window.location.hash.split('/')[1];
    if (countryParam) {
      setCountry(countryParam);
    }
  }, []);

  return (
    <Router>
      <Navbar country={country} setCountry={setCountry} />
      <Routes>
        <Route exact path="/" element={<Navigate to={`/${country}/partners`} state={{ country }} />} />
        <Route path="/:country/partners" element={<Partners country={country} />} />
        <Route path="/:country/texts" element={<Texts country={country} />} />
        <Route path="/:country/calendar" element={<Calendar country={country} />} />
      </Routes>
    </Router>
  );
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <AuthWrapper>
    <App />
  </AuthWrapper>
);
