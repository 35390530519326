import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { COUNTRIES } from '../utils/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';

const NavbarComponent = ({ country, setCountry }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath('/:country/:tab', location.pathname);

  const handleCountryChange = (eventKey) => {
    setCountry(eventKey);
    const newUrl = `/${eventKey}${match.pathname.replace(`/${country}`, '')}`;
    navigate(newUrl);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand>
          Credit App Data
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="Countries" id="basic-nav-dropdown" onSelect={handleCountryChange}>
              {COUNTRIES.map((c) => (
                <NavDropdown.Item key={c.code} eventKey={c.code}>
                  {c.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Item>
              <Link to={`/${country}/partners`} className="nav-link">Partners</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={`/${country}/texts`} className="nav-link">Texts</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={`/${country}/calendar`} className="nav-link">Calendar</Link>
            </Nav.Item>
          </Nav>
          <div className="ml-auto text-light">
            {country}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
